export const captialize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const letterAtIndex = (index) => {
    return ALPHABET[index];
}

export function toPounds(value){
    return "£"+((Number(value)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
}

export function encodePaymentRef(userSub, userEmail) {
    const emailSplit = userEmail.split('@');
    let emailChars = 'NA';
    if (emailSplit.length === 0) throw Error('Invalid email address used to generate payment reference');
    else if (emailSplit.length === 1) emailChars = userEmail.slice(0, 2).toUpperCase();
    else emailChars = `${emailSplit[0][0].toUpperCase()}${emailSplit[1][0].toUpperCase()}`;
    return `BBO/${userSub.slice(userSub.length - 8).toUpperCase()}${emailChars}`;
}

export function decodePaymentRef(reference) {
    const split = reference.split('/');
    if (split.length !== 2) throw Error('Invalid payment reference provided for decoding');
    const uniqueRef = split[1];
    if (uniqueRef.length !== 10) throw Error('Invalid reference length found - must be 10 characters');
    const userSub = uniqueRef.slice(0, 8);
    const emailChars = uniqueRef.slice(8);
    return {
        userSub,
        emailChars
    };
}
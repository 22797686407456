import { Card } from "../components/Card";
import { content } from "../assets/content/home";
import { EventCta } from "../components/EventCta";

export default function Home({event}) {
    return (
        <>
            <div className="cta-container fill-width" style={{backgroundImage: `url(${content.cta.img})`}}>
                <EventCta event={event} />
            </div>
            {(event.state === "post_registration") && <div className="time-container">
                <Card title={"REGISTRATION OPEN FROM 9AM - RUNNING STARTS AT 10AM"} theme="red" />
            </div>}
            <div className="cards-container">
                {
                    content.cards.map((card, index) => 
                        <Card key={index} {...card} />
                    )
                }
            </div>
        </>
    );
}
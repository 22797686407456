const explorersNetworksSeniorsSharedCards = [
    {
        title: "Course Details",
        size: "large",
        body: `For the Explorer, Networker and Leader, the ultimate 'cunning running' course to test your years of experience.
        
        A 5.0Km run to find deviously positioned control points that are often invisible to anyone more than 6 foot away from them. This really tests your accuracy and map to ground interpretation skills.

        All members in this grouping run the same course, so if there is any rivalary in the group between Leaders and Explorers, here's a chance to pit experience against youth and see who between the hare and tortoise will win the race!
        `,
    },
];

const importantTeamCard =
{
    title: "VERY IMPORTANT",
    size: "large",
    theme: "yellow",
    body: 
    <>
        <p>It cannot be stressed enough that teams must stay together for the duration of the Course. Any team arriving at the finish without all members being present will incur penalty points.</p>
        <p>Despite this point being made very clearly at the briefings prior to the start, organisers are still finding invidiuals lost in the woods having split from their team mates.</p>
        <p><b>DO NOT LET THIS HAPPEN TO YOU AND YOUR TEAM</b></p>
    </>
};



export const content = {
    beavers: 
        {
            name: "Beavers",
            age: "6-8 years",
            logo: "/images/logos/logo-beavers.svg",
            cards: [
                {
                    title: "Overview",
                    theme: "blue",
                    size: "small",
                    body: 
                    <ul>
                        <li>Age: 6 to 8 Years Old</li>
                        <li>Course: String</li>
                        <li>Team Size: 1 to Many</li>
                        <li>Map Style: Pre-Marked</li>
                        <li>Fee: £1.00 per Person</li>
                        <li>Distance: 1.0 Km (Est)</li>
                    </ul>,
                },
                {
                    title: "What is it?",
                    size: "large",
                    body: `The String Course is designed to allow the youngest people to take part.

                    A string is laid out and the controls are placed on the string. A map is specially prepared showing the controls as per a normal course.

                    At each control site there will be and normal Control Flag, Pin Punch but instead of a Control Code there is a picture of an animal, superhero or some other topical subject.
                    `,
                },
                {
                    title: "On the day",
                    size: "large",
                    body: `Colony leaders will gather you all together somewhere on the car parking field before then registering your attendance at the central registration tent and paying fees (If not already paid on-line prior to the event. This is a change to previous years).

                    Once registered you will be taken to the String Trail start.

                    Beavers are provided a map, which the Starter annotates with their start time. The Participant then follows the string visiting all the controls and using the pin punches at the controls to punch in the boxes on the map. At the Finish they hand the map in get the finish time added to the map and course time calculated. This is recorded and checked off on the Start list.
                    `
                },
                {
                    title: "Example Maps",
                    theme: "blue",
                    size: "small",
                    modals: [
                        {
                            label: "Map 1",
                            link: "/images/modals/beaversMap.png"
                        }
                    ]
                },
                {
                    title: "Not just for Beavers",
                    theme: "forest-green",
                    size: "small",
                    body: "This course is also open to any young Cubs who are looking to gain experience of what orienteering is about. However, we would suggest that beyond 9 years of age the interest would be very limited.",
                },
                importantTeamCard
            ]
        },
        cubs: {
            name: "Cubs",
            age: "8-10½ years",
            logo: "/images/logos/logo-cubs.svg",
            cards: [
                {
                    title: "Overview",
                    theme: "forest-green",
                    size: "small",
                    body:
                    <ul>
                        <li>Age: 8 to 10½ Years Old</li>
                        <li>Course: Yellow</li>
                        <li>Team Size: 3, 4 or 5</li>
                        <li>Map Style: Pre-Marked</li>
                        <li>Fee: £11.00 per team</li>
                        <li>Distance: 3.0 Km (Est)</li>
                    </ul>,
                },
                {
                    title: "First Time?",
                    size: "large",
                    body: 
                        <>
                            <p>If this is their first attempt at the event you may wish to consider having an adult shadow the group, or use it specifically as a training event for them.</p>
                            <p>Although this will make the team <b>non-competitive</b> for the trophies, it is likely to make the whole event that much more enjoyable and provides a great platform for them to return the following year and compete on their own.</p>
                        </>
                },
                {
                    title: "Course Tips",
                    size: "large",
                    body: `The Cub course is approximately 3.0Km long and checkpoints are positioned either at track junctions or recognisable ground features.

                    A common mistake made on the Cub course is the eagerness to clip the first Checkpoint they come across rather than the next one on their description sheet. With multiple courses operating on the day, not all checkpoints will be relevant to each course. So make sure you are clipping the right one.`,

                },
                {
                    title: "Example Control Cards",
                    theme: "forest-green",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/cubsCard.png"
                        }
                    ]
                },
                {
                    title: "Example Punch Cards",
                    theme: "forest-green",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/cubsPunchCard.png"
                        }
                    ]
                },
                importantTeamCard
            ]
        },
        scouts: {
            name: "Scouts",
            age: "10½-14 years",
            logo: "/images/logos/logo-scouts.svg",
            cards: [
                {
                    title: "Overview",
                    theme: "purple",
                    size: "small",
                    body: <ul>
                        <li>Age: 10½ to 14 Years Old</li>
                        <li>Course: Orange</li>
                        <li>Team Size: 2 or 3</li>
                        <li>Map Style: Blank</li>
                        <li>Fee: £10.00 per team</li>
                        <li>Distance: 4.0 Km (Est)</li>
                    </ul>,
                },
                {
                    title: "On the day",
                    size: "large",
                    body: `Teams will start at 30 second intervals in order to speed the starting process and will be directed by the Starting officials into the correct start grid.

                    Scouts run in teams of 2, known as a Scouting Pairs, and for those who have undertaken the Cub course in previous years are offered a couple of additional challenges.

                    The map they are issued is blank and requires them to copy the checkpoints from the 'Master Maps'. (We will have separate marking up tables for Scouts. One with the Odd Route and one with the Even Route. Ensure you go to the correct table).
                    
                    Secondly the checkpoints will be positioned in less obvious locations compared to the Cubs. The need here is for Scouts to be more accurate with their navigation and interpret the ground they can see from the map they hold.
                    `,
                },
                {
                    title: "Feeling competitive?",
                    size: "large",
                    body: 
                    <>
                        <p>With many Scouts running who have had the experience of the Cub course, this section can become very competitive, with some excellent times being recorded in the past.</p>
                        <p>If, however, this is your first outing on an orienteering course, then take it steady and ensure you don't miss any checkpoints out. You can if you wish be accompanied and register as a <b>non-competitive</b> team.</p>
                    </>
                },
                {
                    title: "Example Control Cards",
                    theme: "purple",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/scoutsCard1.png"
                        },
                        {
                            label: "Card 2",
                            link: "/images/modals/scoutsCard2.png"
                        }
                    ]
                },
                {
                    title: "Example Punch Cards",
                    theme: "purple",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/scoutsPunchCard.png"
                        }
                    ]
                },
                importantTeamCard
            ]
        },
        explorers: {
            name: "Explorers",
            age: "14-18 years",
            logo: "/images/logos/logo-explorers.svg",
            cards: [
                {
                    title: "Overview",
                    theme: "navy",
                    size: "small",
                    body: <ul>
                        <li>Age: 14 to 18 Years Old</li>
                        <li>Course: Green</li>
                        <li>Team Size: 1</li>
                        <li>Map Style: Blank</li>
                        <li>Fee: £6.00 per team</li>
                        <li>Distance: 5.0 Km (Est)</li>
                    </ul>,
                },
                {
                    title: "Example Control Cards",
                    theme: "navy",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/explorersCard.png"
                        }
                    ]
                },
                {
                    title: "Example Punch Cards",
                    theme: "navy",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/explorersPunchCard.png"
                        }
                    ]
                },
                ...explorersNetworksSeniorsSharedCards
            ]
        },
        seniors: {
            name: "Seniors",
            age: "18+ years",
            logo: "/images/logos/logo-seniors.png",
            cards: [
                {
                    title: "Overview",
                    theme: "pink",
                    size: "small",
                    body: <ul>
                        <li>Age: 18+ Years Old</li>
                        <li>Course: Green</li>
                        <li>Team Size: 1</li>
                        <li>Map Style: Blank</li>
                        <li>Fee: £6.00 per team</li>
                        <li>Distance: 5.0 Km (Est)</li>
                    </ul>,
                },
                {
                    title: "Example Control Cards",
                    theme: "pink",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/explorersCard.png"
                        }
                    ]
                },
                {
                    title: "Example Punch Cards",
                    theme: "pink",
                    size: "small",
                    modals: [
                        {
                            label: "Card 1",
                            link: "/images/modals/seniorsPunchCard.png"
                        }
                    ]
                },
                ...explorersNetworksSeniorsSharedCards
            ]
        },
    
}
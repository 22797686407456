export const content = {
        "beavers": {
            name: "Beavers",
            age: "6-8 years",
            logo: "/images/logos/logo-beavers.svg",
        },
        "cubs": {
            name: "Cubs",
            age: "8-10½ years",
            logo: "/images/logos/logo-cubs.svg",
        },
        "scouts" :{
            name: "Scouts",
            age: "10½-14 years",
            logo: "/images/logos/logo-scouts.svg",
        },
        "explorers": {
            name: "Explorers",
            age: "14-18 years",
            logo: "/images/logos/logo-explorers.svg",
        },
        "seniors": {
            name: "Seniors",
            age: "18+ years",
            logo: "/images/logos/logo-seniors.png",
        },
        "group" : {
            name: "Group Trophy",
        }
    
}
export default function Maintenance({loading}) {
    return (
        <>
            {!loading && 
                <div className="App">
                    <header id="fullscreen">
                        <img src={"/images/logos/stacked-white.png"} className="logo" alt="Scouts Bucks Big O Logo" />
                        <span className="nunito-black">Next event: Saturday 7th October 2023</span>
                        <span className="nunito-regular">Sorry, we're down for maintenance at the moment.<br />The site will be back up shortly.</span>
                    </header>
                </div>
            }
        </>
    );
}
import { create } from 'zustand';

export const useRegistrationStore = create((set, get) => ({

  leader: {name: '', email: '', phone: ''},
  setLeader: (leader) => set((state) => ({...state, leader: leader})),
  updateLeader: (props) => set((state) => ({...state, fieldChanged: true, leader: Object.assign(state.leader || {}, props)})),

  district : {value: -1, label: ''},
  group : {value: -1, label: '', districtId: -1},
  setDistrict: (district) => set((state) => ({...state, fieldChanged: true, district: district})),
  setGroup: (group) => set((state) => ({...state, fieldChanged: true, 
    group: {
      ...group,
      esu : group?.esu ?? 0
    },
    teams: 
      state.teams.filter((team) => group?.esu === 0 ? team.section !== 'explorers' : (team.section === 'explorers' || team.section === 'seniors'))
      .map((team) => ({
        ...team,
        name: null
      })),
    hasBeavers: group?.esu === 0 ? state.hasBeavers : false    
  })),
  updateDistrictAndGroupIds: (districtId, groupId) => set((state) => ({
    ...state,
    district: {
      label: state.district.label,
      value: districtId
    },
    group: {
      label: state.group.label,
      value: groupId,
      districtId: districtId,
      esu: state.group.esu
    }
  })),
  
  teams: [],
  nextTeamId: 0,
  hasBeavers: false,
  setHasBeavers: (value) => set((state) => ({...state, hasBeavers: value})),

  fieldChanged: true,
  resetFieldChanged: () => set((state) => ({...state, fieldChanged: false})),

  addTeam: (section) => set((state) => ({
    ...state, 
    fieldChanged: true, 
    teams: [...state.teams, {
        id: state.nextTeamId,
        name: null,
        section: section,
        competitive: true,
        competitors: []
    }],
    nextTeamId: state.nextTeamId + 1,
    hasBeavers: section === "beavers" || state.hasBeavers
  })),
  updateTeam: (teamId, props) => set((state) => ({
    ...state, 
    fieldChanged: true, 
    teams: state.teams.map((team) => {
        if (team.id !== teamId) return team;
        return Object.assign(team, props);
    })
  })),
  setTeamName: (teamId, teamName) => set((state) => ({
    ...state,
    teams: state.teams.map((team) => {
      if (team.id !== teamId) return team;
      return Object.assign(team, {name: teamName});
  })
  })),
  deleteTeam: (teamId, section) => {
    let i = 0;
    set((state) => 
      ({
      ...state,
      fieldChanged: true, 
      teams: state.teams.filter((team) => team.id !== teamId),
      hasBeavers: state.hasBeavers && section !== "beavers"
    }))
  },
  setTeams: (teams) => set((state) => ({
    ...state,
    teams: teams
  })),

  getStore: () => get(),
  clear: () => set({})
}))
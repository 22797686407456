import { Button } from "./Button";

export const EventCta = ({event}) => {

    return (
        <>
        {event && 
            <div className="cta">
                <h1>{event.date}</h1>
                <h2>{event.state === "pre_registration" || event.state === "registration" ? "UPCOMING EVENT" :
                    event.state === "post_registration" ? "SEE YOU SOON!" :
                    "RESULTS LIVE"}
                </h2>
                <p style={{whiteSpace: 'pre-wrap'}}>
                    {event.state === "pre_registration" ? "Registration will be opening shortly for this year's Bucks Big O!" :
                    event.state === "registration" ? "Registration is now open for this year's Bucks Big O! \nRegistration will close at 10PM Thursday 10th October":
                    event.state === "post_registration" ? "Registration is now closed. Come back after the event to see the results!" :
                    "The results are in - Thank you to all who participated in this year's event!"
                }
                </p>

                <div className="buttons purple no-border">
                    {event.state === "registration" && <Button onClick={()=>window.location.href="/registration"} label="Registration" />}
                    {event.state === "results" && <Button onClick={()=>window.location.href="/results"} label="Results" />}
                    <Button onClick={()=>window.location.href="/event"} label={"Learn More"} />
                </div>
            </div>
        }
        </>
    )
}

import { Button } from "./Button";

export const Card = ({headerImg, headerContent, title, body, theme, children, className}) => {
    return (
        <div className={`card ${theme} ${title ? '' : 'no-title'} ${className}`} style={!(body || children) ? {textAlign: 'center'} : {}}>
            {(headerImg || headerContent) && <div className="card-header"  style={headerImg && {backgroundImage: `url(${headerImg})`}}>{headerContent}</div>}
            {title && <h3 className="card-title">{title}</h3>}
            {(body || children) && <div className="card-body">{body || children}</div>}
        </div>
    );
}

export const ModalCard = ({title, headerImg, headerContent, theme, modals, body, setModalContent}) => {
    return (
        <Card
            title={title}
            headerImg={headerImg}
            headerContent={headerContent}
            theme={theme}
            body={
                <>
                <div>
                    {body}
                </div>
                <div className="buttons">
                {modals.map((modal, index) =>
                    <Button key={index} onClick={()=>setModalContent(modal.link)} label={modal.label} />)}
                </div>
                </>
            }
        />
    );
} 
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";

export const content = {
    cards: [
        {
            title: "Arrival",
            headerImg: "/images/gallery/image6.jpg",
            body: `The registration tent will open at 9am on the day, and competitiors will start running from 10am.

            Last registration at 11am.
            
            On arrival, leaders need to organise their teams prior to coming to the registration tent. In the past we have had teams register only then to be shuffled around and set off with incorrect details recorded. This can create challenges for the organisers when accounting for all teams at the end of the event.
            
            Once organised and ready to run, all competitors have to visit the registration tent to be recorded, pay fees, and collect maps and control cards.
            
            Registration tent operators will provide any essential administration instructions.`
        },
        {
            title: "Preparation",
            headerImg: "/images/gallery/image11.jpg",
            body: 
            <div>
                <Card theme='blue' title='Beavers' body='Once your Group Name has been registered and fees paid at the Registration Tent, take your Beavers to the String Trail start, which is located in the woods.
Directions provided by Registration team.'/>
                <Card theme="purple" title="All Other Sections" body="Move from the Registration tent to the Briefing Box, where you will receive a safety brief for the course.
You will then be directed to the Start where you will enter the start sequence.
Ensure your Punch Card is scanned by the Starters before you set off. This will record your start time." />
            </div>
        },
        {
            title: "Course Running",
            headerImg: "/images/gallery/image10.jpg",
            body: 
            <div>
                The maps used are orienteering style and scaled at 1:10,000. These will be issued on the day.
                <br /><br />
                Control sites are marked with orange and white orienteering marker flags, and must be visited in control description sheet order.
                <br /><br />
                Each flag has a unique control code and needle punch attached for punching the checkpoint punch card.
                <br /><br />
                Please leave all control flags as they are found - anyone found tampering with them will be disqualified.
                <br /><br />
                Results are based on the fastest time with ALL controls correctly punched for each course.
            </div>,
            modals: [
                {
                    label: "Example Map",
                    link: "/images/modals/exampleMap.jpg"
                }
            ]
        },
        {
            title: "Finish",
            headerImg: "/images/gallery/image13.jpg",
            body: <>
                <span>Once a team finishes the course and their card is handed in, it will be marked and entered into the system. Once this is done, results will be shown by the registration tent for everyone to see how they did.</span>
                <p>Teams are free to leave as soon as they've finished.</p>
                <Card theme="yellow" body={<b>All entrants MUST report to the finish before leaving for home - even if retiring out.</b>} />
            </>
        },
        {
            title: "Beavers String Course",
            headerImg: "/images/gallery/image12.jpg",
            theme: "blue",
            body: `Beavers have a dedicated string course, which involves following a length of string through the woods. A map is provided to introduce the idea of navigation between points.

            The string course will be open from 9.30 am through until about 1pm.

             Beavers can run the course as many times as they like. Important that Beaver Leaders register your colony at the registration tent to enable the group to be considered for the group trophy.
            `
        },
        {
            title: "Group Trophy",
            headerImg: "/images/gallery/image9.jpg",
            body: `Those Groups who enter a Beaver on the String Course, a Cub Team, and a Scout Pair, will be eligible for the Group Trophy.

            The trophy is awarded to the Group with the fastest combined time of their best Cub and Scout placings.

            Trophies will be presented to the winners on a convenient occasion after the event.

            Can last years trophy holders please ensure that the shields are returned at the start of the event.
            `
        },
        {
            title: "Leaders",
            body: `The primary aim of this event is to offer everyone in Bucks scouting the opportunity to train and develop their skills with map and compass. A fundamental element of any adventurous expedition that Scouting is renowned for offering.

            There are however, prizes for fastest teams and best Group entry. For a group to be eligible for the Group Trophy they must field runners from each of their sections; Beaver, Cub and Scout. (Explorers do not contribute to the Group Trophy).
            
            The morning offers a chance to use the event as a training meeting for young people and adults. Whilst there are many who have been before, if this is your first time, then it is a chance to work with your young people to help build their confidence with map and compass.
            `
        },
        // {
        //     title: "Pictorial Guide",
        //     body: <div>
        //         There is a pictorial guide to the event and pictures from last years event available if required, contact <a target="_blank" href="mailto:rogerwilliams@lineone.net" >Roger Wlliams</a> for a link to the pictures.
        //     </div>
        // },
        {
            title: "Health Form",
            theme: "yellow",
            body: <div>
                <p>If you do not have a Uniformed Leader attending the event site, please ensure the following form has been completed and the respective YP brings it to the registration tent.
</p><p>Units should already have this information to hand as part of your regular activity process and Leaders will be called upon by the organising team for assistance with the necessary information should the need arise.
</p><p>Units with Leaders present at the event do NOT need to supply this information to the Registration team, but must have it available.</p>
                        <Button onClick={() => window.open('documents/Health-Form.pdf', '_blank')} label="CLICK HERE TO DOWNLOAD THE HEALTH FORM" />
            </div>
        },
        {
            title: "Risk Assessment",
            theme: "yellow",
            body: <div>
                        <p>
                        Please read the event Risk Assessment prior to attending the event.
                        Leaders should conduct their own RA for the event relevant to their specific responsibilities.
                        </p>
                        <Button onClick={() => window.open('documents/Risk-Assessment.pdf', '_blank')} label="CLICK HERE TO VIEW THE RISK ASSESSMENT" />
                    </div>
        },
        {
            title: "Feedback",
            body: <div>As organisers of the event, we are always keen to improve the experience of the Bucks Big O and we welcome your feedback on all aspects of the event.
                Please send any comments to the following email address:
                <a target="_blank" href="mailto:enquiries@bucksbigo.org.uk" > enquiries@bucksbigo.org.uk</a></div>
        }
    ],
}
import { useState } from "react";
import { content } from "../assets/content/event";
import "../styles/competitors.css";
import { Card, ModalCard } from "../components/Card";

export default function Event() {

    const [modalContent, setModalContent] = useState(null);

    return (
        <>
            <div className="cards-container">
                    {content.cards.map((card, index) => 
                    card.modals ? <ModalCard key={index} {...card} setModalContent={setModalContent} />
                    : <Card key={index} {...card} />
                        // <Card key={index} {...card} />
                    )} 
            </div>

            {modalContent != null &&
            <div className="modal-container">
                <div className="modal-background" onClick={()=>setModalContent(null)} />
                <div className="modal-exit" onClick={()=>setModalContent(null)}><img src="/images/icons/close.svg" /></div>
                <img className="modal-content" src={modalContent} />
            </div>}
        </>
    );
}
import { Map } from "../../components/Map";
import {Card} from "../../components/Card";

export const content = {

    cta: {
        img: "/images/gallery/image1.jpg",
        header: "October 12th 2024",
        subHeader: "UPCOMING EVENT",
        body: "Registration is now open for the 2024 Bucks Big O!",
        buttons: [
            {
                label: "Registration",
                href: "/registration"
            },
            {
                label: "Learn More",
                href: "/event"
            }
        ]
    },

    cards: [
        {
            title: "Orienteering",
            headerImg: "/images/gallery/image7.jpg",
            body: `Orienteering is an exciting and challenging outdoor sport that exercises mind and body, and for the Cubs and Scouts includes an element of team work.
                
            The aim is to navigate between control points marked on an orienteering map.
            
            The event is open to all ages of scouts from 6 to 99+ years of age.`
        },
        {
            title: "Fees",
            headerImg: "/images/gallery/image6.jpg",
            body: 
            <>
                <ul>
                    <li>String Course - £1.00</li>
                    <li>Cub Course - £11.00 per team</li>
                    <li>Scout Course - £10.00 per team</li>
                    <li>Explorer/Leader Course - £6.00 per person</li>
                </ul>
                <Card theme='purple' body={<span>Pre-payment is available for those who register online. Payments will also be taken on the day of the event. When you come to the registration tent please be ready to pay with Cash, Card, or Contactless.</span>} />
            </>
        },
        {
            title: "Directions",
            headerContent: <Map />,
            body: `From the B4009 direction, drive past the entrance to Wendover Woods on your right and continue up the hill to Aston Hill Lodge.

            Opposite the entrance to Aston Hill Lodge is a farm gate, which will be signposted for the “Bucks Big O".

            Please enter the field and follow Marshals directions.`
        },
        {
            title: "Results",
            headerImg: "/images/gallery/image8.jpg",
            body: `Results will be available after the event to all who have submitted a S.A.E or via e-mail if required.

            A record of results by Group and Section will be published on this website after the event.

            Data security: Please DO NOT republish onto your own websites the circulated results as they contain details of YP.

            Queries to Dave Emmett, Linwood, Summerleys Road, Princes Risborough, Buckinghamshire, HP27 9QD 
            Tel: 01844 346 285
            `
        },
        {
            title: "Helpers",
            headerImg: "/images/gallery/image14.jpg",
            body: `As well as organising your young people on the day, we would be very grateful for any help you or other adults from your group can offer to help with marshalling out on the course. 

            If you are able to assist on the day, please make yourself known to Dave Emmett on the day or drop him an email in advance.
            `
        },
        {
            title: "The Country Code",
            headerImg: "/images/gallery/image15.jpg",
            body: "As guests of the Forestry Commission please respect the glorious environment within which we are free to enjoy our scouting event and obey the Country Code - that includes NO LITTER BEING DROPPED"
        }
    ]
}
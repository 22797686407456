import { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { isUnderMaintenance } from "../services/features";
import { slide as Menu } from "react-burger-menu";

import "../styles/burger.css";
import "../styles/layout.css";

export default function Layout({loading}) {


  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  return (
    <>
      {!loading &&
        <>
          <div id={"topbar"} className="nunito-black">
          <a className="topbar-link topbar-img topbar-middle" href="/">
            <img className={"topbar-img"} src={"images/logos/stacked-white.png"} />
          </a>
          {isDesktop ?
            <NavbarLinks isDesktop />
            :
            <Menu right>
              <NavbarLinks />
            </Menu>
          }
          </div>
          <div id={"topbar-placeholder"} />
          <Outlet />
        </>
      }
    </>
  )
};

const NavbarLinks = ({isDesktop}) => {
  const links = ['Competitors','Event','Results','Registration'];
  return links.map((link, index) => <a key={index} className={`topbar-link ${isDesktop ? index < (links.length-1)/2 ? 'topbar-left' : 'topbar-right' : 'menu-item'}`} href={`/${link.toLowerCase()}`}>{link}</a>)
};
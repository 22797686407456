import { useState } from "react";
import { content } from "../assets/content/competitors";
import "../styles/competitors.css";
import { Card, ModalCard } from "../components/Card";
import { SectionSelector } from "../components/SectionSelector";

export default function Competitors() {

    const [sectionIndex, setSectionIndex] = useState(-1);
    const [selectedSection, setSelectedSection] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    return (
        <>
            <SectionSelector sections={content.competitors} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />

            <div className="cards-container">
                {selectedSection ?
                    <>
                        <div className="cards-column small">
                            {content[selectedSection].cards.filter(card => card.size == "small").map((card, index) => 
                                card.modals ? <ModalCard key={index} {...card} setModalContent={setModalContent} />
                                : <Card key={index} {...card} />
                            )}
                        </div>

                        <div className="cards-column large">
                            {content[selectedSection].cards.filter(card => card.size == "large").map((card, index) => 
                                card.modals ? <ModalCard key={index} {...card} setModalContent={setModalContent} />
                                : <Card key={index} {...card} />
                            )}
                        </div>
                    </>
                    :
                    <Card title="Select one of the sections above to learn more about what to expect on the day" />
                }
            </div>

            {modalContent != null &&
            <div className="modal-container">
                <div className="modal-background" onClick={()=>setModalContent(null)} />
                <div className="modal-exit" onClick={()=>setModalContent(null)}><img src="/images/icons/close.svg" /></div>
                <img className="modal-content" src={modalContent} />
            </div>}
        </>
    );
}
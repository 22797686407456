import axios from "axios";
import { API_HOST } from "./api_config";

export async function isUnderMaintenance() {
    if (process.env.NODE_ENV) return false;
    try {
        const res = await axios.get(`${API_HOST}/features/maintenance`);
        return res.data;
    }
    catch (error) {
        return null;
    }
}
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Layout from "./pages/layout";
import Home from "./pages/home";
import Maintenance from "./pages/maintenance";
import Error from "./pages/error";
import { isUnderMaintenance } from './services/features';
import Competitors from './pages/competitors';
import Event from './pages/event';
import Results from './pages/results';
import Registration from './pages/registration';
import { API_HOST } from './services/api_config';
import { nth } from './utils/dateUtils';
import { Policy } from './pages/policy';

function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [registrationOpen, setRegistrationOpen] = useState(null);

  useEffect(() => {
    isUnderMaintenance().then((res) => {
      if (res == null) console.log("ERROR");
      else if (res && location.pathname != "/maintenance") navigate("/maintenance");
      else if (!res && location.pathname == "/maintenance") navigate("/");
    });

    if (location.pathname == "/" || location.pathname == "/registration") {
      fetch(`${API_HOST}/events/latest`)
      .then(result => result.json())
      .then(eventData => {
          const eventDate = new Date(eventData.event[0].date);
          const dateString = eventDate.toLocaleDateString("en-US", {day: "numeric", month: "long", year: "numeric"}).replace(",", nth(eventDate.getDate()));
  
          const resultsUploaded = eventData.event[0].resultsUploaded;
          const regOpen = new Date(eventData.event[0].registrationOpen);
          const regClose = new Date(eventData.event[0].registrationClose);
  
          const currentTime = new Date();
  
          let eventState;
          if (resultsUploaded) {
              eventState = "results";
          } else if (currentTime < regOpen) {
              eventState = "pre_registration";
          } else if (currentTime < regClose) {
              eventState = "registration";
          } else {
              eventState = "post_registration";
          }
  
          setEvent({
              id: eventData.event[0].id,
              date: dateString,
              state: eventState,
              sections: {
                beavers: eventData.event[0].beavers,
                cubs: eventData.event[0].cubs,
                scouts: eventData.event[0].scouts,
                explorers: eventData.event[0].explorers,
                seniors: eventData.event[0].seniors,
              }
          });

          setLoading(false);
      }).catch((err) => {
          console.log(err);
          navigate("/error");
      })
    } else {
      setLoading(false);
    }

  }, []);

  return (
      <Routes>
        <Route path="/" element={<Layout loading={loading} />}>
          <Route index element={<Home event={event} />} />
          <Route path="/competitors" element={<Competitors />} />
          <Route path="/event" element={<Event />} />
          <Route path="results" element={<Results />} />
          <Route path="registration" element={<Registration event={event} />} />
          <Route path="termsofservice" element={<Policy />}/>
          <Route path="privacystatement" element={<Policy />}/>
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/maintenance" element={<Maintenance loading={loading} />} />
      </Routes>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

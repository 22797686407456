import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useState } from "react";

  const markers = [
    {
        position: {lat:51.781759252736286, lng:-0.7089769515731834},
        address: "",
        title: "Parking + Registration Area - HP22 5NQ"
    }
  ]

export function Map() {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANtjGRbBwR9YgO8hrPCxEBcMroPr6kTXA"
      })
    
      const [map, setMap] = useState(null)
    
      const onLoad = useCallback(function callback(map) {
        map.setCenter(markers[0].position);
        map.setOptions({disableDefaultUI: true})
        setMap(map)
      }, [])
    
      const onUnmount = useCallback(function callback(map) {
        setMap(null)
      }, [])
    
      return isLoaded ? (
          <GoogleMap
            mapContainerStyle={{height: "300px"}}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {
                markers.map((marker, index) => <CustomMarker key={index} marker={marker} />)
            }
          </GoogleMap>
      ) : <></>

}

function CustomMarker({marker}) {
    const [infoWindowShown, setInfoWindowShown] = useState(false);

    return (
        <Marker position={marker.position} label={marker?.label}
            onClick={()=>setInfoWindowShown(true)}>
            {infoWindowShown && 
            <InfoWindow
            position={marker.position}
            onCloseClick={()=>setInfoWindowShown(false)}
            >
                <div>
                    {marker.title && <p>{marker.title}</p>}
                    {marker.address && <p>{marker.address}</p>}
                    <a href={`https://www.google.com/maps/search/?api=1&query=${marker.position.lat},${marker.position.lng}`} target="_blank">View on Google Maps</a>
                </div>
            </InfoWindow>
            
            }
        </Marker>
    );

}
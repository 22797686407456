import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { PulseLoader, SyncLoader } from "react-spinners";
import DatePicker from "react-date-picker";

import 'react-date-picker/dist/DatePicker.css';
import { Card } from "../components/Card";
import { Button } from "../components/Button";
import CreatableSelect from "react-select/creatable";
import { API_HOST } from "../services/api_config";
import { useRegistrationStore } from "../stores/registration-store";
import "../styles/registration.css";
import { captialize, encodePaymentRef, toPounds } from "../utils/stringUtils";
import { TextInput } from "../components/TextInput";
import axios from "axios";

const sections = {
    beavers: {
        theme: 'blue',
        min: 1,
        max: 1,
        price: 1,
    },
    cubs: {
        theme: 'forest-green',
        min: 3,
        max: 5,
        price: 11,
    },
    scouts: {
        theme: 'purple',
        min: 2,
        max: 3,
        price: 10,
    },
    explorers: {
        theme: 'navy',
        min: 1,
        max: 1,
        price: 6,
    },
    seniors: {
        theme: 'pink',
        min: 1,
        max: 1,
        price: 6
    },
};

export default function Registration({event}) {
    return (
        <Auth0Provider
            domain="bucks-big-o.uk.auth0.com"
            clientId="c6GrwD8Wy9AQXlVCEG3X9kpxa4RaerNG"
            authorizationParams={{
            redirect_uri: `${window.location.origin}/registration`
            }}
        >
            <Page event={event} />
        </Auth0Provider>
    );
}

const Page = ({event}) => {
    const { logout, user } = useAuth0();
    const [updateLeader, setDistrict, setGroup, setTeams, setHasBeavers, group, leader] = useRegistrationStore((state) => [state.updateLeader, state.setDistrict, state.setGroup, state.setTeams, state.setHasBeavers, state.group, state.leader]);
    const [isFetching, setIsFetching] = useState(true);
    const [loadError, setLoadError] = useState(null);
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) setTimeout(loginWithRedirect, 1000);
    }, [isAuthenticated, isLoading, loginWithRedirect]);

    useEffect(() => {
        if (!user) return;
        axios.post(`${API_HOST}/submissions/fetch`, {
            authId: user.sub,
            eventId: event.id
        },
        {
            headers: {
              'Content-Type': 'application/json',
            }
        }).then((res) => {
            if (res && res.data) {
                updateLeader(res.data.leader);
                setDistrict(res.data.district);
                setGroup(res.data.group);
                setTeams(res.data.teams);
                if (res.data.teams.filter((team) => team.section === "beavers").length > 0) setHasBeavers(true);
            } 
            setTimeout(() => setIsFetching(false), 500);
        }).catch((err) => {
            setLoadError(err);
        })
    }, [user]);

    return (
        loadError ? <Card theme="red" title="Error" body="There has been an error connecting to registrations. Please check  your internet connection, and refresh the page to try again." /> :
        (isLoading || isFetching) ? <FSLoader /> :
        <div className="cards-container">
            <div className="cards-column small">
                <LeaderDetails user={user} logout={logout} />
                <GroupDetails />
                <Overview eventId={event.id} allowSubmissions={event.state === "registration"} />
                <Payment sub={user.sub} leaderDetails={leader} />
            </div>
            <div className="cards-column large">
                <Card theme="yellow" title="NEW FOR THIS YEAR">
                <p>If you do not have a Uniformed Leader attending the event site, please ensure the following form has been completed and the respective YP brings it to the registration tent.
</p><p>Units should already have this information to hand as part of your regular activity process and Leaders will be called upon by the organising team for assistance with the necessary information should the need arise.
</p><p>Units with Leaders present at the event do NOT need to supply this information to the Registration team, but must have it available.</p>
                    <Button onClick={() => window.open('documents/Health-Form.pdf', '_blank')} label="CLICK HERE TO DOWNLOAD THE HEALTH FORM" />
                </Card>
                <Card theme="yellow" title="RISK ASSESSMENT">
    <p>
    Please read the event Risk Assessment prior to attending the event.
    Leaders should conduct their own RA for the event relevant to their specific responsibilities.
    </p>
    <Button onClick={() => window.open('documents/Risk-Assessment.pdf', '_blank')} label="CLICK HERE TO VIEW THE RISK ASSESSMENT" />
    </Card>
                <AllTeams allowSubmissions={event.state === "registration"}  />
                {event.state === "registration" && <AddTeam availableSections={event.sections} />}
            </div>
        </div>
    );
}

const Overview = ({eventId, allowSubmissions}) => {
    const [teams, getStore, hasBeavers, fieldChanged, resetFieldChanged, setTeamName, updateDistrictAndGroupIds] = useRegistrationStore((state) => [state.teams, state.getStore, state.hasBeavers, state.fieldChanged, state.resetFieldChanged, state.setTeamName, state.updateDistrictAndGroupIds]);
    const [counts, setCounts] = useState([]);
    const [submissionState, setSubmissionState] = useState("none");

    useEffect(() => {
        setCounts(
            Object.keys(sections).map((section, index) => {
            const count = section === "beavers" ?
            hasBeavers ? teams.find((team) => team.section === section)?.competitors.length : 0
            : teams.filter((team) => team.section === section).length;
            return {section: section, count: count, price: count * sections[section].price};
            })
        )
    }, [teams, hasBeavers]);

    useEffect(() => {
        if (fieldChanged && (submissionState === "invalid" || submissionState === "submitted")) {
            setSubmissionState("none");
        }
    }, [fieldChanged, submissionState]);

    const validateSubmission = () => {
        const currentStore = getStore();
        console.log(currentStore);

        resetFieldChanged();

        // Required Text Fields
        const textFields = document.getElementsByClassName("required");
        for (let i = 0; i < textFields.length; i++) {
            if (textFields[i].value === "") textFields[i].classList.add("invalid");
        }

        // Date of Birth Fields
        const dateFields = document.getElementsByClassName("react-date-picker");
        for (let i = 0; i < dateFields.length; i++) {
            if ((dateFields[i].parentElement.classList.contains("required") || dateFields[i].previousSibling.value !== "") && 
            dateFields[i].getElementsByTagName("input")[0].value === "") dateFields[i].classList.add("invalid")
        }

        // Group Details
        const groupControls = document.getElementsByClassName("react-select__control");
        if (currentStore.district === null) groupControls[0].classList.add("invalid");
        if (currentStore.group === null) groupControls[1].classList.add("invalid");

        const invalid = document.getElementsByClassName("invalid").length;

        if (invalid > 0) {
            setSubmissionState("invalid");
            return;
        }

        setSubmissionState("submitting");

        // Submit everything to database
        axios.post(`${API_HOST}/submissions/submit`, {
            leader: currentStore.leader,
            district: currentStore.district,
            group: currentStore.group,
            teams: currentStore.teams,
            eventId: eventId
        }, 
        {
            headers: {
              'Content-Type': 'application/json'
            }
        }).then((res) => {
            console.log(res);
            const teamNames = res.data.teamNames;
            for (var i = 0; i < teamNames.length; i++) {
                setTeamName(teams[i].id, teamNames[i]);
            }

            updateDistrictAndGroupIds(res.districtId, res.groupId);
            
            setTimeout(() => setSubmissionState("submitted"), 500);
        }).catch((err) => {
            console.log(err);
            setSubmissionState("error");
        })
    }

    const submissionStates = {
        "none" : {
            label: "Submit Teams",
            onClick: validateSubmission,
            theme: ''
        },
        "invalid" : {
            label: "Missing Required Fields",
            onClick: null,
            theme: 'red',
        },
        "submitting" : {
            label: <p>Submitting Teams<PulseLoader color="#000000" size={10} /></p>,
            onClick: null,
            theme: '',
        },
        "error" : {
            label: "Submission Error",
            sublabel: "There has been an error submitting your teams, please try again, or if the issue persists, please contact enquiries@bucksbigo.org.uk",
            onClick: validateSubmission,
            theme: 'red',
        },
        "submitted" : {
            label: "Teams Submitted",
            sublabel: "Your teams have now been allocated names, and you will receive an email shortly with details of your submission.",
            onClick: null,
            theme: 'forest-green',
        }
    }

    return (
        <Card title="Overview">
            {
                counts.map((entry, index) => {
                    if (entry.count === 0) return;
                    let identifier = captialize(entry.section);
                    if (entry.count === 1 || entry.section === "cubs" || entry.section === "scouts") identifier = identifier.slice(0, identifier.length - 1);
                    return <div key={index} className="pricing"><div>{entry.count} {identifier} {(entry.section === "cubs" || entry.section === "scouts") ? entry.count === 1 ? 'Team' : 'Teams' : ''}</div><div> {toPounds(entry.price)}</div></div>
                })
            }
            <div className="pricing-total">*Total: {toPounds(counts.map((entry) => entry.price).reduce((partialSum, a) => partialSum + a, 0))}</div>
            
            {allowSubmissions && 
                <div className={`buttons ${submissionStates[submissionState].theme}`} style={{gap: '4px'}}>
                    <Button label={submissionStates[submissionState].label} onClick={submissionStates[submissionState].onClick} />
                    {submissionStates[submissionState].sublabel && <Button label={submissionStates[submissionState].sublabel} /> }
                </div>
            }
            <a href="/privacystatement" className="registration-tips">Click here to view our privacy policy.</a>
        </Card>
    )
}

const Payment = ({sub, leaderDetails}) => {

    const [paymentReference, setPaymentReference] = useState(null);
    useEffect(() => {
        if (!sub || !leaderDetails || !leaderDetails.email) return;
        try {
            setPaymentReference(encodePaymentRef(sub, leaderDetails.email));
        } catch(err) {
            console.warn(err);
            setPaymentReference(null);
        }
    }, [sub, leaderDetails]);
        
    return (
        <Card title="Payments">
            <div className="registration-tips">*The price shown above is based on the number of teams and competitors you have entered.</div>
            <div className="registration-tips">
            <b>Pre-Pay</b><br />
            Once you have finalized your teams and submitted them, you have the option to pre-pay via Bank Transfer to speed up your registration on the day. <br /><br />
            All pre-payments should be sent to <b>"Buckinghamshire Scout Council"</b> (or <b>"Bucks Scouts"</b> or <b>"Bucks Scout Council"</b> if the long version is not accepted).
            <br /><br />Sort Code: <b>20 02 06</b>    
            <br />Account Number: <b>20552046</b>
            <br />Reference: <b>{paymentReference ? paymentReference : 'There was an issue generating your unique reference number, please try again later.'}</b>
            <br /><br /><b>All pre-payments must be with us at least 24 hours before the event.</b>
            </div>
            <div className="registration-tips">
                <b>On the Day</b><br />
                If you choose not to pre-pay, we will accept payment on the day with Cash, Card, or Contactless.
            </div>
        </Card>
    )
}

const Competitors = ({section, competitors, setCompetitors}) => {
    useEffect(() => {
        const defaultCompetitors = [...competitors];
        for (let i = 0; i < defaultCompetitors.length; i++) {
            if (i < sections[section].min || section === "beavers") defaultCompetitors[i].required = true;
        }
        for (let i = defaultCompetitors.length; i < sections[section].max; i++) {
            if (i < sections[section].min) defaultCompetitors[i] = {required: true, name: '', dob: ''};
            else defaultCompetitors[i] = {required: false, name: '', dob: ''};
        }
        setCompetitors(defaultCompetitors);
    }, [section]);

    const updateCompetitor = (competitorIndex, props) => {
        setCompetitors(
            competitors.map((competitor, index) => {
                if (competitorIndex !== index) return competitor;
                return Object.assign(competitor, props);
            })
        )
    };

    const addCompetitor = (required) => {
        setCompetitors([...competitors, {required: required, name: '', dob: ''}]);
    }

    const onKeyDown = (e, index) => {
        if (e.target.value !== null && e.target.parentElement.classList.contains("invalid")) e.target.parentElement.classList.remove("invalid");

        if (section !== "beavers") return;

        if (e.code === "Tab" && e.target.classList.contains("react-date-picker__inputGroup__year") && index === competitors.length - 1) {
            addCompetitor(true);
        }
    }

    return (
        <div className="team-competitors-container">
            {
                competitors.map((competitor, index) =>
                    <div className={`team-competitor ${competitor.required ? 'required' : ''}`} key={index}>
                        <TextInput required={competitor.required} placeholder="Name" value={competitor.name} onChange={(v) => updateCompetitor(index, {name: v.target.value})} />
                        <DatePicker disableCalendar clearIcon={null} onKeyDown={(e) => onKeyDown(e, index)} 
                        onChange={(v) => {console.log(v); updateCompetitor(index, {dob: v})}}
                        value={competitor.dob} dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder={`YYYY${competitor.required ? '*' : ''}`} />
                    </div>
                )
            }
            {
                section === "beavers" &&
                <div className="buttons team-competitor">
                    <Button label="Add Beaver" onClick={()=>addCompetitor(true)} />
                    <Button label="Delete Last Beaver" disabled={competitors.length <= 1} onClick={() => {setCompetitors([...competitors.filter((_, index) => index < competitors.length - 1)])}} />
                </div>
            }
        </div>
    );
}

const CompetitiveRadio = ({teamId, competitive, setCompetitive}) => {
    return (
        <div className="competitive-radio">
            <label>
                <input type="radio" defaultChecked={competitive} name={`competitive-${teamId}`} id={`competitive-${teamId}`} onChange={() => setCompetitive(true)} />
                Competitive (No Adult Assistance)
            </label>
            <label>
                <input type="radio" defaultChecked={!competitive} name={`competitive-${teamId}`} id={`non-competitive-${teamId}`}  onChange={() => setCompetitive(false)}/>
                Non-Competitive (Training/Experience)
            </label>
        </div>
    );
}

const AllTeams = ({allowSubmissions}) => {
    const [teams, updateTeam, deleteTeam] = useRegistrationStore((state) => [state.teams, state.updateTeam, state.deleteTeam]);

    if (allowSubmissions && teams.length === 0) return <Card title="Enter your contact and group/unit details to begin. Then you can start adding teams by Section below. When you are done entering competitors, go to the Overview section to view pricing and Submit teams. After submitting, you will be allocated available team names." />
    

    return (
        <>
        {!allowSubmissions && <Card title="Registration is now closed. If you need to make anymore changes to your teams, you'll need to make them in person at the event." />}
        {teams.map((team, index) =>
            <Card key={team.id} title={`${team.section !== "beavers" ? `Team ${team.name === null ? '?' : team.name} - ` : ''} ${captialize(team.section)}`} theme={sections[team.section].theme}>

                <div className="team-props">
                    {team.section !== "explorers" && team.section !== "seniors" && <TextInput className="pack" placeholder="Pack/Troop Name (if applicable)" defaultValue={team.pack} onChange={(v) => updateTeam(team.id, {pack: v.target.value})}/>}
                    {team.section !== "beavers" && <CompetitiveRadio teamId={team.id} competitive={team.competitive} setCompetitive={(v) => updateTeam(team.id, {competitive : v})} />}
                </div>

                <div className="delete" onClick={() => deleteTeam(team.id, team.section)}>
                    <img src="/images/icons/bin.svg" />
                </div>

                <Competitors section={team.section} competitors={team.competitors} setCompetitors={(v) => updateTeam(team.id, {competitors: v})} />
            </Card>
        )}
        </>
    )
}

const AddTeam = ({availableSections}) => {
    const [addTeam, hasBeavers, group] = useRegistrationStore((state) => [state.addTeam, state.hasBeavers, state.group]);

    return (
        <Card title="Add Team" className="add-team-container">
            <div className="buttons">
                {
                    Object.keys(sections).filter((section) => 
                    (availableSections[section] === 1 && ((!hasBeavers && section === "beavers") || section !== "beavers") &&
                    ((group && group.esu === 1 && (section === 'explorers' || section === 'seniors')) || (group && group.esu === 0 && section !== 'explorers') || (group?.label === ''))
                    ))
                    .map((section, index) => 
                    <Button key={index} disabled={group?.label === ''} img={`/images/logos/logo-${section}.svg`} className={sections[section].theme} onClick={()=>addTeam(section)} />)
                }
            </div>
        </Card>
    );
}

const LeaderDetails = ({user, logout}) => {
    const [leader, updateLeader] = useRegistrationStore((state) => [state.leader, state.updateLeader]);

    useEffect(() => {
        if (user) updateLeader({email: user.email, authId: user.sub});
    }, [user]);

    return (
        <Card title="Your Details">
            <TextInput required placeholder="Name" value={leader.name} onChange={(v) => updateLeader({name: v.target.value})} />
            <TextInput required placeholder="Email" disabled value={user.email} />
            <TextInput required placeholder="Phone" value={leader.phone} onChange={(v) => updateLeader({phone: v.target.value})} />
            <div className="buttons">
                <Button label="Logout" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
            </div>
        </Card>
    )
}

const GroupDetails = () => {
    const [districtOptions, setDistrictOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]); 
    const [loadError, setLoadError] = useState(false);

    const [district, group, setDistrict, setGroup] = useRegistrationStore(state => [state.district, state.group, state.setDistrict, state.setGroup]);

    const groupRef = useRef();

    useEffect(() => {
        fetch(`${API_HOST}/groups/all`)
        .then(result => result.json())
        .then(data => {
            console.log(data)
            try {
                setDistrictOptions(data.districts.map((district) => ({value: district.id, label: district.name})));
                setGroupOptions(data.groups.map((group) => ({value: group.id, label: group.name, districtId: group.districtId, esu: group.esu })));
            } catch(err) {
                setLoadError(true);
                console.log(err);
            }
        })
    }, []);

    return (
        <Card title="Group/Unit/Network Details">
            <CreatableSelect 
                classNamePrefix="react-select"
                options={districtOptions} 
                value={district?.label === '' ? null : district}
                placeholder="District"
                onChange={(v) => {
                    setDistrict(v);
                    setGroup({value: -1, label: '', districtId: -1});
                }}
            />
            <CreatableSelect 
                classNamePrefix="react-select"
                isDisabled={district?.label === ''}
                ref={groupRef}
                value={group?.label === '' ? null : group}
                placeholder="Group/Unit"
                options={groupOptions.filter((group) => district && group.districtId === district.value)} 
                onChange={(v) => {
                    setGroup(v);
                }} 
            />
            {group && group.__isNew__ && 
                <div className="esu-checkbox">
                    <input type="checkbox" onChange={(v)=>{setGroup({...group, esu: v.target.checked ? 1 : 0})}} />
                    <label>Is this an ESU or Network?</label>
                </div>
            }
            {group && group.value === -1 && 
                <p className="registration-tips">
                    <b>Can't find your group?</b><br />
                    If your group or district isn't listed above, type it in and select 'Create' to add it to our database.
                </p>
            }
            { loadError && <Card theme="red" body="There was an issue loading the districts and groups, please refresh the page to try again." />}
        </Card>
    )
}

const FSLoader = () => {
    return (
        <div className="loader-container">
            <SyncLoader color="#7413dc" size={20} />
        </div>
    );
}

import { useEffect, useMemo, useRef, useState } from "react";
import { SectionSelector } from "../components/SectionSelector";
import {content} from "../assets/content/results";
import { AgGridReact } from "ag-grid-react";
import { API_HOST } from "../services/api_config";

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS


import "../styles/results.css";
import { Card } from "../components/Card";
import { nth } from "../utils/dateUtils";
import { Button } from "../components/Button";

export default function Results() {

    const [errorMessage, setErrorMessage] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [allEvents, setAllEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchEvents = () => {
        fetch(`${API_HOST}/events/completed`)
        .then(result => result.json())
        .then(data => {
            setAllEvents(data.events);
        })
        .catch(err => {
            setErrorMessage(err);
        });
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchResults = () => {
        setRowData([]);
        setIsLoading(true);
        fetch(`${API_HOST}/results/${selectedEvent.id}/${selectedSection}`)
        .then(result => result.json())
        .then(data => {
            if (data.message) throw new Error(data.message);
            setRowData(data.map((team, index) => {
                if (selectedSection === 'group') {
                    return {
                        pos: index + 1,
                        group: team.groupName,
                        combinedTime: new Date(team.combined * 1000).toISOString().slice(11, 19),
                    }   
                } else {
                    return {
                        pos: index + 1,
                        team: `${team.groupName}${team.packName ? ` - ${team.packName}` : ''} [${team.teamName}]`,
                        time: new Date(team.elapsed * 1000).toISOString().slice(11, 19),
                        errors: team.errors,
                        competitive: team.competitive,
                    }   
                }
            }));
            setIsLoading(false);
        })
        .catch(err => {
            setErrorMessage(err);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (selectedSection != null) {
            fetchResults();
        } else {
            setRowData([]);
        }
    }, [selectedSection]);

    useEffect(() => {
        setSelectedSection(null);
    }, [selectedEvent]);


    // useEffect(() => {
    //     if (sectionIndex >= 0) {
    //         fetch(`${API_HOST}/results/${content.competitors[sectionIndex].search}`)
    //         .then(result => result.json())
    //         .then(rowData => {
    //             if (rowData.message) throw new Error(rowData.message);
    //             setRowData(rowData.map((team, index) => {
    //                 if (content.competitors[sectionIndex].search === 'group') {
    //                     return {
    //                         pos: index + 1,
    //                         group: team.groupName,
    //                         combinedTime: new Date(team.combined * 1000).toISOString().slice(11, 19),
    //                     }   
    //                 } else {
    //                     return {
    //                         pos: index + 1,
    //                         team: `${team.groupName}${team.subsectionName ? ` - ${team.subsectionName}` : ''} [${team.teamName}]`,
    //                         time: new Date(team.elapsed * 1000).toISOString().slice(11, 19),
    //                         errors: team.errors,
    //                         competitive: team.competitive,
    //                     }   
    //                 }
    //             }));
    //         })
    //         .catch(err => {
    //             setRowData([]);
    //             setErrorMessage(err.toString());
    //         });
    //     } else {
    //         setRowData([]);
    //     }
    // }, [sectionIndex]);

    return (
        <>
        {allEvents && selectedEvent == null && <AllEvents events={allEvents} setSelectedEvent={setSelectedEvent} />}

        {allEvents && selectedEvent != null &&
            <>
                <div className="cards-container section-cards">
                    <Card theme="purple"><Button label="Back to All Events" onClick={()=>{setSelectedEvent(null)}} /></Card>
                    <div className="non-compete-info"><p>Non-competitive teams are shown in purple</p></div>
                </div>
                <SectionSelector event={selectedEvent} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
                <ResultsTable results={rowData} isGroup={selectedSection === "group"} errorMessage={errorMessage} isLoading={isLoading} />
            </>
        }
        </>
    );

}

const AllEvents = ({events, setSelectedEvent}) => {
    return (
        <div className="cards-container">
            {events.map((event, index) => {
                return (
                    <Card key={index} className="event-card" theme="purple" title={event.name}>
                        <Button label="View Results" onClick={()=>{setSelectedEvent(event)}} />
                    </Card>
                )
            })}
        </div>
    );
}

const ResultsTable = ({results, isGroup, errorMessage, isLoading}) => {

    const gridRef = useRef();
    const [columns, setColumns] = useState(null);

    const resize = () => {
        if (gridRef && gridRef.current && gridRef.current.api) gridRef.current.api.sizeColumnsToFit()
    }

    useEffect(() => {
        document.addEventListener('resize', resize);
    }, []);

    useEffect(() => {
        resize();
    }, [results]);

    useEffect(() => {
        setColumns(isGroup ? 
            [
                {field: 'pos', maxWidth: 70},
                {field: 'group', flex: 1, wrapText: true, autoHeight: true},
                {field: 'combinedTime'},
            ] :
            [
                {field: 'pos', maxWidth: 70},
                {field: 'team', flex: 1, wrapText: true, autoHeight: true},
                {field: 'time', maxWidth: 120},
                {field: 'errors', maxWidth: 100},
                {field: 'competitive', hide: true}
            ]
        );
    }, [isGroup]);

    const defaultColDef = useMemo(()=>({
        sortable: true,
    }));

    const rowClassRules = {
        'non-competitive' : function(params) {return params.data.competitive === 0}
    }

    return (
        <div className="results-container">
            <div id="myGrid" className="ag-theme-alpine">
                <AgGridReact
                    key={isLoading && errorMessage}
                    ref={gridRef}
                    rowData={results} // Row Data for Rows
                    columnDefs={columns} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    rowClassRules={rowClassRules}
                    animateRows={false} // Optional - set to 'true' to have rows animate when sorted
                    domLayout="autoHeight"
                    overlayNoRowsTemplate={isLoading ? 'Loading...' : errorMessage ? errorMessage.toString() : "Select a section or the group trophy to view this year's results."}
                />
            </div>
        </div>
    )
}
export const Policy = () => {
    return (
        <>
            <div class="policy-container">
                <h1 class="title">Policy Statements</h1>
                <div class="policy-section">
                    <h2>Copyright</h2>
                    <div class="policy-subsection">
                        <h3>Material</h3>
                        <p>The material contained within this site is subject to copyrights.</p>
                    </div>
                    <div class="policy-subsection">
                        <h3>Terms</h3>        
                        <p>All capitalised terms such as Cub, Scout, Beaver, Group, Troop, Pack etc are recognised as marks of the Scout Association.</p>
                        <p>All Scout logos, and Scoutbase logos are registered marks / marks of the Scout association.</p>
                    </div>
                    <div class="policy-subsection">
                        <h3>Use</h3>        
                        <p>Use of material from these pages without the expressed permission of "The Scout Association" or "Bucks County Scouts", where appropriate, is forbidden.</p>
                    </div> 
                </div>

                <div class="policy-section">
                    <h2>Exemption</h2>
                    <p>Material contained within these pages may be freely used, by members of the UK Scout Association for bona fide Scouting purposes. Otherwise it must not be sold, reproduced or repeated in any other form (paper, electronic, video etc) either in full or in part, without the written permission of the copyright holder.</p>
                    <p>Bucks County Scouts would appreciate acknowledgment of material developed by them.</p>
                </div>

                <div class="policy-section">
                    <h2>Complaints</h2>
                    <p>Bucks County Scouts do not knowingly use copyrighted information where use has not been granted. If you believe any item contained on this site has been used without the copyright owner's permission please contact enquiries@bucksbigo.org.uk</p>
                </div>

                <div class="policy-section">
                    <h2>Privacy</h2>
                    <div class="policy-subsection">
                        <h3>Use of Information</h3>
                        <p>Information supplied to and contained within this website is for the purpose of promoting and supporting Scouting, in general and more specifically in Bucks Scouts. It is to both support current Members of the Association and to encourage new members to join.</p>
                        <p>All photos submitted to the site must have prior approval for use on the internet by the image owner.</p>
                    </div>
                    <div class="policy-subsection">
                        <h3>Personal Details</h3>
                        <ul>
                            <li>
                                <h4>For Leaders/Representatives with Scouting Roles</h4>
                                <p>Information such as personal e-mail addresses, will be used to allow forwarders to be set up such that site users may direct their query to the most appropriate person. The WebMaster may also use the e-mail address or personal address of a Leader/Representative with a Scouting role to contact them directly regarding the operation of the site, or a 'diary' event / advertised activity, submitted by them or on their behalf.</p>
                            </li>
                            <li>
                                <h4>For Advertisers/Facilities</h4>
                                <p>When you supply your personal or company details as part of an advert for a resource or facility, these will be made available on the web pages of this site or supplied to users requesting such information. Other details will only be used by the WebMaster or Bucks Scouts administration to contact them directly regarding the operation of the site.</p>
                            </li>
                            <li>
                                <h4>For Users</h4>
                                <p>If you choose to supply personal details as part of an enquiry, those details will be used and passed to other Bucks County Scouts/ Scout Association representatives such that your query may be resolved. (See also Information Gathering)</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="policy-section">
                    <h2>Information Gathering</h2>
                    <div class="policy-subsection">
                        <h3>Cookies</h3>
                        <p>This site uses cookies to identify users that have previously logged in, so they can be automatically logged in for registrations.</p>
                    </div>
                    <div class="policy-subsection">
                        <h3>Pop-Ups</h3>
                        <p>We have no intention to provide pop-ups. Site links may, however, generate a new target window for clarity of information.</p>
                    </div>
                </div>

                <div class="policy-section">
                    <h2>Guidance - UK Scouting Websites</h2>
                    <p>Fact sheet: Guidance for Scouting websites in the UK is detailed in a factsheet available on Scoutbase.org.UK as FS295207 - Developing a Scouting Website</p>
                </div>

                <div class="policy-section">
                    <h2>Disclaimer</h2>
                    <p>Views and opinions contained within this site are those of the Bucks County Scouts Big O organiser authors and contributors and may not accurately reflect the views and opinions of the Scout Association as a whole.</p>
                </div>

                <div class="policy-section">
                    <h2>Links</h2>
                    <div class="policy-subsection">
                        <h3>Internal</h3>
                        <p>This is a stand alone site with only one external link which is providing information only.</p>
                    </div>
                    <div class="policy-subsection">
                        <h3>Links to domains outside bucksbigo.org.uk</h3>
                        <p>These are the responsibility of the linked site owner and are not endorsed or controlled by Bucks County Scouts. Permission to use, and responsibility for, the information contained in linked sites should be sought from the domain owner.</p>
                    </div>
                </div>

                <h2>Any queries or issues related to this site should be directed to : enquiries@bucksbigo.org.uk</h2>
            </div>
            <div class="policy-container">
                <h1>Privacy Policy</h1>
                <div class="policy-section">
                    <p>www.bucksbigo.org.uk and Buckinghamshire Scouts take the issue of privacy very seriously and we are committed to protecting and respecting our users' privacy. This Privacy Statement sets out our current data processing practices and should be read in conjunction with our Terms & Conditions.</p>
                    <ol>
                        <li>
                            <h2>The Information we collect</h2>
                            <p>The data we collect is used solely for the purposes of running the Bucks Scouts Annual Orienteering Event, known as the “Bucks Big O”. No competitor, team, or group data collected is shared with any third party, however it is stored on a third party hosting provider within a secure database accessible only to BucksBigO personnel.</p>
                            <p>Account authorisation is handled by a third party, Auth0 by Okta, and as such accounts created for use with Bucks Big O registration are handled by them. The only data they store are the details you provide to create your account. If you'd like to learn more about how they manage your data visit https://auth0.com/security</p>
                        </li>
                        <li>
                            <h2>Registration information</h2>
                            <p>When you register a team with www.bucksbigo.org.uk, we ask for the name, email address and contact number of the leader/responsible adult. We usually also capture your IP address at the time you register.</p>
                        </li>
                        <li>
                            <h2>Sharing of data</h2>
                            <p>No data is shared with anyone outside of Bucks County Scouts organisation.</p>
                        </li>
                        <li>
                            <h2>Advertising</h2>
                            <p>www.bucksbigo.org.uk will not include any interactive advertising for other businesses as part of the site operation. We will not provide to third parties, your contact details.</p>
                        </li>
                        <li>
                            <h2>Legal jurisdiction</h2>
                            <p>All personal information and details held on and processed by computers situated in the United Kingdom.</p>
                        </li>
                        <li>
                            <h2>Further information</h2>
                            <p>www.bucksbigo.org.uk will as part of it's normal operation send out to information emails. Members can decide to ask for these emails as they register.</p>
                        </li>
                        <li>
                            <h2>Disclosure of data by order of a Court and Security</h2>
                            <p>We reserve the right to communicate a Member's personal information as we hold to third parties who are empowered by regulation, statute or order of a court.</p>
                            <p>We have security measures in place to protect our registered users database. Access to this database is restricted internally.</p>
                            <p>It is the responsibility of the registered users to protect the links provided via secure email as this allows access to your details for editing purposes.</p>
                        </li>
                        <li>
                            <h2>Cookies</h2>
                            <p>This site uses cookies to identify users that have previously logged in, so they can be automatically logged in for registrations.</p>
                        </li>
                        <li>
                            <h2>The Data Protection Act</h2>
                            <p>You can obtain further information about data protection laws by visiting the Information Commissioner's website at www.dataprotection.gov.uk.</p>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    )
}
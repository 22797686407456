import "../styles/sectionSelector.css";
import {content} from "../assets/content/results";

export const SectionSelector = ({event, selectedSection, setSelectedSection}) => {
    return (
        <div className="sections-container">
            {
                Object.keys(content).filter((section) => (event === undefined && section !== "group") || (event && section !== "beavers" && (event[section] === 1 || section === "group"))).map((section, index) => 
                <div className={`section-selector ${selectedSection === section && 'active'}`} key={index} onClick={()=>setSelectedSection(section)}>
                    {content[section].logo ?
                    <>
                        <img src={content[section].logo} />
                        <p>{content[section].age}</p>
                    </>
                    :
                        <h3>{content[section].name}</h3>
                    }
                </div>)
            }
        </div>
    );
}
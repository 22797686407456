export const TextInput = ({required, disabled, placeholder, defaultValue, className, value, onChange}) => {
    return (
        <input 
        className={`${className ? className : ""} ${required ? "required" : ""}`}
        placeholder={`${placeholder}${required ? "*" : ""}`} 
        defaultValue={defaultValue} 
        disabled={disabled}
        value={value}
        onChange={(v) => {
            if (v.target.classList.contains("invalid") && v.target.value !== "") v.target.classList.remove("invalid");
            onChange(v);
        }} />
    )
}